import React from "react"
import { Card, Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Button from "../Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import "./style.scss"

export default ({
  relationships,
  imageAlt = "Missing Alt Text",
  published,
  title,
  link,
  source,
  isFeatured = false,
}) => {
  let defaultClasses = "card community resource"

  const imageThumb =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail
  const imageFeatured =
    relationships?.field_image?.relationships?.field_media_image?.featuredImage

  const NewsCard = ({ className }) => (
    <a
      href={link ? link.uri : "#"}
      className={className ? `${defaultClasses} ${className}` : defaultClasses}
      target="_blank"
      rel="noopener noreferrer"
    >
      {imageFeatured?.childImageSharp?.fixed?.originalName !== "cyd.png" ? (
        <Img
          className={"resource-img-card"}
          key={"img-thumb-" + imageThumb?.originalName}
          loading={"lazy"}
          fixed={imageThumb?.childImageSharp?.fixed}
          alt={imageAlt}
          
        />
      ) :
        <img 
          className={"resource-img-card"}
          src={require("../../assets/images/FSRIwordmarkBlue.png")}
          alt={imageAlt}
        />
      }
      <Container className={"labels"} fluid>
        <Row className={"pt-3 px-3 align-items-center"}>
          <Col>
            <Card.Text className={"source-location source-color"}>
              {source}
            </Card.Text>
          </Col>
        </Row>
      </Container>
      <Card.Title className="p-3 mb-0">{title}</Card.Title>
      <Card.Text className={"px-3 caption"}>{published}</Card.Text>
      <Container className={"action-links"} fluid={true}>
        <Row>
          <Col>
            <Button
              icon={`arrow-right`}
              variant={"btn-transparent-blue"}
              className={"btn btn-transparent-blue ml-3"}
              label={"Read More"}
            />
          </Col>
        </Row>
      </Container>
    </a>
  )

  if (isFeatured) {
    return (
      <>
        <Card
          className={"featured alt d-none d-md-block"}
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="1200"
          data-sal-easing="ease"
        >
          <a
            href={link ? link.uri : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {imageFeatured?.childImageSharp?.fixed?.originalName !== "cyd.png" ? (
              <Img
                key={
                  "img-featured-" +
                  imageFeatured?.childImageSharp?.fixed?.originalName
                }
                loading={"lazy"}
                fixed={imageFeatured?.childImageSharp?.fixed}
                alt={imageAlt}
              />
            ) :
              <div className="gatsby-image-wrapper">
                <img 
                  alt={imageAlt}
                  src={require("../../assets/images/FSRIwordmarkBlue.png")}
                />
              </div>
            }
            <Card.ImgOverlay className={"pl-0"}>
              <Col md={6} className={"position-absolute pb-0 pb-lg-3"}>
                <Card.Title>{title}</Card.Title>
                <Card.Text className={"caption"}>{published}</Card.Text>
                <p className={"card-link mb-0 pb-lg-3"}>
                  <FontAwesomeIcon icon={"arrow-right"} />
                  {"Read More"}
                </p>
              </Col>
            </Card.ImgOverlay>
          </a>
        </Card>
        <NewsCard className={"p-0 d-md-none"} />
      </>
    )
  } else {
    return <NewsCard className={"p-0"} />
  }
}
